import * as React from "react";
import { Hello } from "../../Hello/Hello";

export const Home = () => {
  return (
    <>
      <Hello />
    </>
  );
};
